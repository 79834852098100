@use '../breakpoints';

.about {
	margin-top: 8vh;

	.heading,
	h1,
	p {
		text-align: left;
	}

	.about-content {
		.description {
			.badges {
				padding-bottom: 15px;
				display: flex;

				a {
					margin: 0 auto;
					img {
						margin: 0 auto;
						height: 3.7vw;
						@include breakpoints.media(">=tablet") {
							height: 2.3vw;
						}
					}
				}
			}

			button.showAllCertificates {
				margin-bottom: 2vh;
				width: 100%;
				padding: 5px;

				font-size: 15px;
				background-color: #e7e7e746;
				color: black;

				svg {
					margin: 0 5vw;
				}
			}

			.certificates-content {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr));
				grid-gap: 1vw;

				@include breakpoints.media(">=tablet") {
					grid-template-columns: repeat(auto-fit, minmax(15vw, 1fr));
				}
			}
		}

		@include breakpoints.media(">=tablet") {
			display: flex;
		}

		img {
			border-radius: 5%;

			@include breakpoints.media("<tablet") {
				height: 50vw;
			}

			@include breakpoints.media(">=tablet") {
				float: right;
				height: 30vw;
				margin-left: 5vw;
				margin-top: 2vh;
			}

			@include breakpoints.media(">=LGDesktop") {
				height: 20vw;
				margin-top: 1vh;
			}
		}
	}

	.skill-title {
		text-decoration: underline;
	}

	.skill-table {
		border-collapse: collapse;
		width: 100%;

		.outer-bar {
			border-radius: 3pt;
			width: 100%;
			background-color: lightgray;
		}

		.inner-bar {
			border-radius: inherit;
			color: white;
			font-size: 0.7em;
			font-weight: bold;
			background-color: #9352ae;
		}

		tbody tr {
			border-top: 1px;
			border-right: 0px;
			border-left: 0px;
			border-bottom: 0px;
			border-color: #00000050;
			border-style: solid;
		}
	}
}
